<template>
  <c-form-control pos="relative">
    <c-input-group>
      <c-input
        :value="value_"
        type="text"
        :height="['48px', '62px']"
        :font-size="['14px', '18px']"
        color="primary.400"
        font-weight="500"
        placeholder="Masukkan hari ke"
        border-top-right-radius="8px"
        border-bottom-right-radius="8px"
        border-top-left-radius="8px"
        border-bottom-left-radius="8px"
        focus-border-color="primary.400"
        padding="0 20px"
        readonly
        @click.prevent="onToogle"
      />
      <c-flex
        pos="absolute"
        right="0"
        height="100%"
        width="fit-content"
        color="#008C81"
        justify-content="center"
        align-items="center"
        margin-right="1rem"
        margin-left="1rem"
        cursor="pointer"
        z-index="2"
      >
        <c-box
          transform="rotate(90deg)"
          @click.prevent="onToogle"
        >
          <inline-svg
            :src="require('@/assets/icon-chevron-right.svg')"
            height="24px"
            width="24px"
          />
        </c-box>
      </c-flex>
    </c-input-group>
    <c-flex
      v-if="isOpen"
      v-on-clickaway="onClose"
      pos="absolute"
      right="0"
      :top="['48px', '62px']"
      width="fit-content" 
      flex-direction="column" 
      border="1px solid"
      border-color="inherit" 
      border-radius="12px" 
      p="8px"
      background-color="#FFF"
      z-index="2"
    >
      <c-flex
        width="100%"
        align-items="center"
        justify-content="space-between"
        mb="8px"
      >
        <c-button
          flex-shrink="0"
          variant="ghost"
          p="0"
          width="30px"
          height="30px"
          @click.prevent="onPrevMonth"
        >
          <c-image
            :src="require('@/assets/icon-accordion.svg')"
            :h="['16px']"
            transform="rotate(-180deg)"
            animation="1s transform ease"
          />
        </c-button>
        <c-text
          flex-grow="1"
          text-align="center"
          color="#555555"
          font-weight="600"
          font-size="18px"
        > 
          Bulan {{ month_ }} 
        </c-text>
        <c-button
          flex-shrink="0"
          variant="ghost"
          p="0"
          width="30px"
          height="30px"
          @click.prevent="onNextMonth"
        >
          <c-image
            :src="require('@/assets/icon-accordion.svg')"
            :h="['16px']"
            transform="rotate(0deg)"
            animation="1s transform ease"
          />
        </c-button>
      </c-flex>
      <c-grid
        width="100%"
        template-columns="repeat(7, 1fr)"
        gap="8px"
      >
        <DayPopover
          v-for="day2 in days"
          :key="`${month_}-${day2}`"
          :day="day2"
          :month="month_"
          :content="checkUsedContent(month_, day2)"
          :selected="modelValue"
          @click="onSelectDay"
        />
      </c-grid>
    </c-flex>
  </c-form-control>
</template>

<script>
import DayPopover from '@/views/admin/coaching-challenge/_widgets/day-popover.vue'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'SelectDateContent',
  components: { DayPopover },
  mixins: [clickaway],
  props: {
    day: {
      type: [Number, null],
      default: null,
    },
    month: {
      type: [Number, null],
      default: null,
    },
    attributes: {
      type: Array,
      default: () => [],
    },
    contentId: {
      type: String,
      default: '',
    },
  },
  emits: ['update:day', 'update:month'],
  data() {
    return {
      month_: 1,
      day_: 1,
      days: 30,
      isOpen: false,
    }
  },
  computed: {
    modelValue: {
      get() {
        return {
          month: this.month,
          day: this.day,
        }
      },
      // set(val) {
      //   console.log(val)
      //   this.$emit('input', val)
      // },
    },
    value_() {
      return this.modelValue?.month && this.modelValue?.day ? `Bulan ${this.modelValue?.month}, hari ke ${this.modelValue?.day}` : null
    },
  },
  mounted() {
    if (this.month && this.day) {
      this.month_ = this.month
    }
  },
  methods: {
    onToogle() {
      this.isOpen = !this.isOpen
    },
    onClose() {
      this.isOpen = false
    },
    onPrevMonth() {
      if (this.month_ > 1) {
        this.month_ = this.month_-1
      }
    },
    onNextMonth() {
      if (this.month_ < 12) {
        this.month_ = this.month_+1
      }
    },
    onSelectDay(data) {
      this.$emit('update:day', data.day)
      this.$emit('update:month', data.month)
      this.onClose()
    },
    checkUsedContent(month, day) {
      if (this.attributes.length > 0) {
        const find = this.attributes.find((it) => it?.showOnDay == day && it?.monthGroup == month)
        if (find && (find?.id !== this.contentId)) {
          return find?.title
        }
      }
      return null
    },
  },
}
</script>

<style></style>
