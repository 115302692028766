var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLabel', {
    attrs: {
      "value": _vm.modelValue,
      "label": "Deskripsi Konten",
      "is-required": ""
    }
  }, [_c('BaseEditor', {
    attrs: {
      "image-provider-url": "/v1/admin/content-and-challenge/upload"
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }