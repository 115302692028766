var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('BaseInputText', {
    attrs: {
      "label": "Link YouTube",
      "placeholder": "Masukkan Link YouTube",
      "is-required": ""
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }), _c('BaseLabel', {
    attrs: {
      "value": _vm.description,
      "label": "Caption Video",
      "full-width": ""
    }
  }, [_c('c-textarea', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '16px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n        border: '1px solid #C4C4C4',\n        padding: '16px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"
    }],
    attrs: {
      "font-size": "18px",
      "color": "primary.400",
      "font-weight": "500",
      "focus-border-color": "primary.400",
      "value": _vm.description,
      "rows": "4",
      "placeholder": "Masukkan Caption Video"
    },
    on: {
      "change": function change(value) {
        return _vm.$emit('change-description', value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }