var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "align": "baseline",
      "gap": "32px"
    }
  }, [_c('BaseLabel', {
    attrs: {
      "value": _vm.image,
      "label": "Gambar",
      "is-required": ""
    }
  }, [_c('label', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        bg: 'white',
        cursor: 'pointer',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '6px',
        w: '180px',
        h: '100%',
        minHeight: '180px',
        d: 'flex',
        p: '0',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      expression: "{\n        bg: 'white',\n        cursor: 'pointer',\n        border: '1px solid #C4C4C4',\n        boxSizing: 'border-box',\n        borderRadius: '6px',\n        w: '180px',\n        h: '100%',\n        minHeight: '180px',\n        d: 'flex',\n        p: '0',\n        alignItems: 'center',\n        justifyContent: 'center',\n        overflow: 'hidden',\n      }"
    }],
    attrs: {
      "for": "img-program-".concat(_vm.idx)
    }
  }, [_vm.image ? _c('c-image', {
    attrs: {
      "src": _vm.getPreviewImage(_vm.image),
      "h": !_vm.getPreviewImage(_vm.image).includes('icon-photo') ? '100%' : '94px',
      "alt": "img program",
      "mx": "auto",
      "border-radius": "6px"
    }
  }) : _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-image-preview.svg'),
      "height": "100px",
      "width": "100px",
      "fill": "#008C81"
    }
  })], 1), _c('c-input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "img-program-".concat(_vm.idx),
      "type": "file",
      "accept": ".jpg, .jpeg, .png",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Deskripsi Konten",
      "height": "62px"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event);
      }
    }
  })], 1), _c('BaseLabel', {
    attrs: {
      "value": _vm.description,
      "label": "Caption Gambar",
      "full-width": ""
    }
  }, [_c('c-textarea', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '16px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n        border: '1px solid #C4C4C4',\n        padding: '16px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"
    }],
    attrs: {
      "font-size": "18px",
      "color": "primary.400",
      "font-weight": "500",
      "focus-border-color": "primary.400",
      "value": _vm.description,
      "min-height": "180px",
      "placeholder": "Masukkan Caption Gambar"
    },
    on: {
      "change": function change(value) {
        return _vm.$emit('change-description', value);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }