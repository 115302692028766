var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "on-close": _vm.onClose,
      "close-on-overlay-click": false,
      "size": "xl",
      "is-centered": "",
      "scroll-behavior": "outside",
      "overflow-y": "hidden",
      "max-height": "100vh"
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "border-radius": "1rem",
      "height": "fit-content",
      "margin": "0"
    }
  }, [_c('c-modal-header', [_c('c-text', {
    attrs: {
      "font-size": "20px"
    }
  }, [_vm._v(" Filter ")])], 1), _c('c-modal-close-button'), _c('c-modal-body', {
    attrs: {
      "max-height": "60vh",
      "overflow-y": "auto"
    }
  }, _vm._l(_vm.data, function (category) {
    return _c('c-box', {
      key: category.id,
      attrs: {
        "w": "100%"
      }
    }, [_c('c-flex', {
      attrs: {
        "justify-content": "space-between",
        "align": "center",
        "mb": "8px"
      }
    }, [_c('c-flex', {
      attrs: {
        "align": "center",
        "gap": "1rem"
      }
    }, [_c('c-image', {
      attrs: {
        "w": "40px",
        "h": "40px",
        "src": category.image
      }
    }), _c('c-text', {
      attrs: {
        "font-size": "18px",
        "font-weight": "600"
      }
    }, [_vm._v(" " + _vm._s(category.categoryName) + " ")])], 1), _c('c-flex', {
      attrs: {
        "font-size": "18px",
        "font-weight": "600"
      }
    }, [_c('c-checkbox', {
      attrs: {
        "size": "lg",
        "flex-direction": "row-reverse",
        "color": "primary",
        "variant-color": "primary",
        "is-checked": _vm.isCategoryChecked(category.id),
        "pointer-events": _vm.isCategoryDisabled(category.subCategories) ? 'none' : 'unset'
      },
      on: {
        "change": function change(val, $e) {
          return _vm.onCheckCategory(category.id, $e.target.checked, category.subCategories);
        }
      }
    })], 1)], 1), _c('c-flex', {
      attrs: {
        "mb": "1rem",
        "w": "100%",
        "flex-wrap": "wrap",
        "p": "8px",
        "gap": "4"
      }
    }, _vm._l(category === null || category === void 0 ? void 0 : category.subCategories, function (sub) {
      return _c('c-checkbox', {
        key: sub.id,
        attrs: {
          "variant-color": "primary",
          "size": "lg",
          "font-size": ['14px', '16px'],
          "font-weight": "400",
          "color": "#111",
          "is-inline": "",
          "is-checked": _vm.subs_.includes(sub.id)
        },
        on: {
          "change": function change(val, $e) {
            return _vm.onChangeSubcatories(category.id, sub.id, $e.target.checked);
          }
        }
      }, [_vm._v(" " + _vm._s(sub.subCategoryName) + " ")]);
    }), 1)], 1);
  }), 1), _c('c-modal-footer', [_c('BaseButton', {
    attrs: {
      "mx": "auto",
      "width": ['150px', '300px']
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSave.apply(null, arguments);
      }
    }
  }, [_vm._v(" Simpan Filter ")])], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }