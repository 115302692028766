<template>
  <c-box>
    <BaseInputText
      v-model="modelValue"
      label="Link YouTube"
      placeholder="Masukkan Link YouTube"
      is-required
    />
    <BaseLabel
      :value="description"
      label="Caption Video"
      full-width
    > 
      <c-textarea
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '16px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        font-size="18px"
        color="primary.400"
        font-weight="500"
        focus-border-color="primary.400"
        :value="description"
        rows="4"
        placeholder="Masukkan Caption Video"
        @change="(value) => $emit('change-description', value)"
      />
    </BaseLabel>
  </c-box>
</template>

<script>
import BaseInputText from '@/components/elements/base-input-text.vue'
import BaseLabel from '@/components/elements/base-label.vue'

export default {
  name: 'SectionYoutube',
  components: {
    BaseInputText,
    BaseLabel,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
  },
  emits: ['change-description', 'change-url'],
  computed: {
    modelValue: {
      get() {
        return this.url
      },
      set(val) {
        this.$emit('change-url', val)
      },
    },
  },
}
</script>
