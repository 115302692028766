var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BaseLabel', {
    attrs: {
      "value": _vm.description,
      "label": "Caption Carousel",
      "full-width": ""
    }
  }, [_c('c-textarea', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        border: '1px solid #C4C4C4',
        padding: '16px',
        width: '100%',
        borderRadius: '6px',
        height: '180px',
        '&:focus, &:focus-visible': {
          outline: 'unset'
        }
      },
      expression: "{\n        border: '1px solid #C4C4C4',\n        padding: '16px',\n        width: '100%',\n        borderRadius: '6px',\n        height: '180px',\n        '&:focus, &:focus-visible': {\n          outline: 'unset',\n        },\n      }"
    }],
    attrs: {
      "font-size": "18px",
      "color": "primary.400",
      "font-weight": "500",
      "focus-border-color": "primary.400",
      "value": _vm.description,
      "rows": "4",
      "placeholder": "Masukkan Caption Gambar"
    },
    on: {
      "change": function change(value) {
        return _vm.$emit('change-description', value);
      }
    }
  })], 1), _c('BaseLabel', {
    attrs: {
      "value": _vm.photos_,
      "label": "Gambar Carousel",
      "is-required": "",
      "full-width": ""
    }
  }, [_c('draggable', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: '20px',
        margin: '0 auto'
      },
      expression: "{\n        display: 'grid',\n        gridTemplateColumns: 'repeat(4, 1fr)',\n        gap: '20px',\n        margin: '0 auto',\n      }"
    }],
    attrs: {
      "draggable": ".item"
    },
    model: {
      value: _vm.photos_,
      callback: function callback($$v) {
        _vm.photos_ = $$v;
      },
      expression: "photos_"
    }
  }, [_vm._l(_vm.photos, function (photo, indexPhoto) {
    return _c('c-box', {
      key: indexPhoto,
      staticClass: "item",
      attrs: {
        "min-height": "150px",
        "width": "100%",
        "mx": "auto"
      }
    }, [!_vm.isDataEmpty(photo) ? _c('c-box', {
      attrs: {
        "position": "relative",
        "d": "inline-block",
        "w": "100%"
      }
    }, [_c('c-image', {
      staticStyle: {
        "object-fit": "cover"
      },
      attrs: {
        "src": _vm.getPreviewImage(photo.url),
        "width": "100%",
        "max-height": "250px",
        "rounded": "0",
        "cursor": "move"
      }
    }), _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            background: 'white'
          }
        },
        expression: "{\n              ':hover' : {\n                background: 'white'\n              }\n            }"
      }],
      attrs: {
        "p": "0",
        "m": "0",
        "background-color": "white",
        "border-radius": "100%",
        "position": "absolute",
        "top": "-8px",
        "right": "-8px",
        "min-width": "30px",
        "height": "30px",
        "width": "30px"
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangePhoto('delete-photo', indexPhoto, $event);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-close.svg'),
        "height": "30px",
        "width": "30px",
        "fill": "red"
      }
    })], 1), _c('c-button', {
      attrs: {
        "width": "100%",
        "display": "flex",
        "align": "center",
        "justify": "center",
        "gap": "8px"
      },
      on: {
        "click": function click($event) {
          return _vm.handleOpenFile(indexPhoto);
        }
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-image-preview.svg'),
        "height": "15px",
        "width": "15px",
        "fill": "#1F2937"
      }
    }), _vm._v(" Ganti Gambar ")], 1)], 1) : _vm._e(), _c('c-button', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isDataEmpty(photo),
        expression: "isDataEmpty(photo)"
      }],
      attrs: {
        "p": "1rem",
        "h": "fit-content",
        "min-height": "150px",
        "w": "100%",
        "as": "label",
        "d": "flex",
        "border": "1px solid #C4C4C4",
        "justify": "center",
        "rounded": "8px",
        "overflow": "hidden",
        "bg": "white",
        "cursor": "pointer"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-image-preview.svg'),
        "height": "100px",
        "width": "100px",
        "fill": "#008C81"
      }
    }), _c('input', {
      ref: "file-".concat(indexPhoto),
      refInFor: true,
      attrs: {
        "type": "file",
        "accept": ".jpg, .jpeg, .png",
        "hidden": ""
      },
      on: {
        "change": function change($event) {
          return _vm.handleChangePhoto('input-photo', indexPhoto, $event);
        }
      }
    })], 1)], 1);
  }), _vm.photos_.length < 10 ? _c('c-flex', {
    attrs: {
      "width": "100%",
      "min-height": "150px",
      "justify": "center",
      "align-items": "center"
    }
  }, [_c('BaseButton', {
    attrs: {
      "width": "100%",
      "size": "medium",
      "border-radius": "1000px",
      "variant": "outlined"
    },
    on: {
      "click": function click($event) {
        return _vm.handleChangePhoto('add');
      }
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "16px"
    }
  }, [_vm._v(" Tambah Gambar ")]), _c('c-box', {
    attrs: {
      "as": "span",
      "font-size": "14px",
      "color": "#888888"
    }
  }, [_vm._v(" (max: 10) ")])], 1)], 1)], 1) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }