<template>
  <c-flex align="baseline">
    <c-form-control
      min-w="180px"
      w="180px"
      mr="40px"
      :is-invalid="true"
    >
      <c-form-label
        font-size="14px"
        color="#555555"
        font-weigh="400"
        font-family="Roboto"
      >
        Upload File
      </c-form-label>
      <label
        v-chakra="{
          bg: 'white',
          cursor: 'pointer',
          border: '1px solid #C4C4C4',
          boxSizing: 'border-box',
          borderRadius: '6px',
          w: '180px',
          h: '180px',
          d: 'flex',
          p: '0',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }"
        :for="`file-program-${idx}`"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-folder.svg')"
          height="140"
          width="140"
          fill="#008C81"
        />
      </label>
      <c-input
        :id="`file-program-${idx}`"
        style="display: none"
        type="file"
        accept=".pdf,.doc,.docx,application/msword"
        error-border-color="red.300"
        placeholder="Masukkan Deskripsi Konten"
        height="62px"
        @change="onFileChange($event)"
      />
    </c-form-control>
    <c-form-control
      align-self="center"
      w="100%"
      :is-invalid="true"
    >
      <c-form-label
        font-size="14px"
        color="gray.900"
        font-weigh="normal"
        font-family="Roboto"
      >
        {{ getPreviewFile }}
      </c-form-label>
    </c-form-control>
  </c-flex>
</template>

<script>
import { CFlex, CInput, CFormControl, CFormLabel } from '@chakra-ui/vue'

export default {
  name: 'SectionUploadFile',
  components: {
    CFlex,
    CInput,
    CFormControl,
    CFormLabel,
  },
  props: {
    description: {
      type: [String, Object, File],
      default: '',
    },
    image: {
      type: [String, Object, File],
      default: '',
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change-image'],
  computed: {
    getPreviewFile() {
      if (this.image?.name) {
        return this.image.name
      }
      if (JSON.parse((this?.description || null))?.fileName) {
        return JSON.parse((this?.description || null))?.fileName
      }
      return 'File maksimal 3 MB'
    },
  },
  methods: {
    onFileChange(input) {
      if (input?.target?.files?.[0]) {
        let file = input.target.files[0]

        this.$emit('change-image', file)
      }
    },
  },
}
</script>
