<template>
  <BaseLabel
    :value="modelValue"
    label="Deskripsi Konten"
    is-required
  >
    <BaseEditor
      v-model="modelValue"
      image-provider-url="/v1/admin/content-and-challenge/upload"
    />
  </BaseLabel>
</template>

<script>
import BaseEditor from '@/components/elements/base-editor.vue'
import BaseLabel from '@/components/elements/base-label.vue'

export default {
  name: 'SectionDescription',
  components: {
    BaseLabel,
    BaseEditor,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
