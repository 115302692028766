<template>
  <c-box
    width="100%"
  >
    <BaseLabel
      :value="description"
      label="Caption Carousel"
      full-width
    > 
      <c-textarea
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '16px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        font-size="18px"
        color="primary.400"
        font-weight="500"
        focus-border-color="primary.400"
        :value="description"
        rows="4"
        placeholder="Masukkan Caption Gambar"
        @change="(value) => $emit('change-description', value)"
      />
    </BaseLabel>
    <BaseLabel
      :value="photos_"
      label="Gambar Carousel"
      is-required
      full-width
    >
      <draggable
        v-model="photos_"
        v-chakra="{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '20px',
          margin: '0 auto',
        }"
        draggable=".item"
      >
        <c-box
          v-for="(photo, indexPhoto) in photos"
          :key="indexPhoto"
          min-height="150px"
          width="100%"
          mx="auto"
          class="item"
        >
          <c-box
            v-if="!isDataEmpty(photo)"
            position="relative"
            d="inline-block"
            w="100%"
          >
            <c-image
              :src="getPreviewImage(photo.url)"
              style="object-fit: cover;"
              width="100%"
              max-height="250px"
              rounded="0"
              cursor="move"
            />
            <c-button
              v-chakra="{
                ':hover' : {
                  background: 'white'
                }
              }"
              p="0"
              m="0"
              background-color="white"
              border-radius="100%"
              position="absolute"
              top="-8px"
              right="-8px"
              min-width="30px"
              height="30px"
              width="30px"
              @click="handleChangePhoto('delete-photo', indexPhoto, $event)"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-circle-close.svg')"
                height="30px"
                width="30px"
                fill="red"
              />
            </c-button>
            <c-button
              width="100%"
              display="flex"
              align="center"
              justify="center"
              gap="8px"
              @click="handleOpenFile(indexPhoto)"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-image-preview.svg')"
                height="15px"
                width="15px"
                fill="#1F2937"
              />
              Ganti Gambar
            </c-button>
          </c-box>
          <c-button
            v-show="isDataEmpty(photo)"
            p="1rem"
            h="fit-content"
            min-height="150px"
            w="100%"
            as="label"
            d="flex"
            border="1px solid #C4C4C4"
            justify="center"
            rounded="8px"
            overflow="hidden"
            bg="white"
            cursor="pointer"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-image-preview.svg')"
              height="100px"
              width="100px"
              fill="#008C81"
            />
            <input
              :ref="`file-${indexPhoto}`"
              type="file"
              accept=".jpg, .jpeg, .png"
              hidden
              @change="handleChangePhoto('input-photo', indexPhoto, $event)"
            >
          </c-button>
        </c-box>
        <c-flex
          v-if="photos_.length < 10"
          width="100%"
          min-height="150px"
          justify="center"
          align-items="center"
        >
          <BaseButton
            width="100%"
            size="medium"
            border-radius="1000px"
            variant="outlined"
            @click="handleChangePhoto('add')"
          >
            <!-- :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
            left-svg-icon-color="#008C81" -->
            <c-flex
              flex-direction="column"
              align-items="center"
              justify-content="center"
            >
              <c-text font-size="16px">
                Tambah Gambar
              </c-text>
              <c-box
                as="span"
                font-size="14px"
                color="#888888"
              >
                (max: 10)
              </c-box>
            </c-flex>
          </BaseButton>
        </c-flex>
      </draggable>
    </BaseLabel>
  </c-box>
</template>

<script>
import { isDataEmpty } from '@/utils/is-data-empty'
import BaseLabel from '@/components/elements/base-label.vue'
import BaseButton from '@/components/elements/base-button.vue'
import draggable from 'vuedraggable'

export default {
  name: 'SectionCarousel',
  components: {
    BaseButton,
    BaseLabel,
    draggable,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    photos: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['change-description', 'change-photos'],
  computed: {
    photos_: {
      get() {
        return this.photos
      },
      set(val) {
        this.$emit('change-photos', val)
      },
    }, 
  },
  methods: {
    isDataEmpty,
    handleOpenFile(index) {
      if (this.$refs[`file-${index}`]) {
        this.$refs[`file-${index}`][0]?.click()
      }
    },
    handleChangePhoto(type, indexPhoto, e) {
      if (type === 'add') {
        const newPhotos = [...this.photos, {}]
        this.$emit('change-photos', newPhotos)
        return
      }

      if (type === 'input-photo') {
        const imageFile = e.target.files?.[0]
        const newPhotos = [...this.photos]
        newPhotos[indexPhoto] = {
          url: imageFile,
        }
        this.$emit('change-photos', newPhotos)
        return
      }

      if (type === 'delete-photo') {
        const newPhotos = [...this.photos]
        newPhotos[indexPhoto] = {}
        this.$emit('change-photos', newPhotos)
        return
      }
    },
    getPreviewImage(url) {
      if (url && typeof url == 'string') {
        return url
      }
      if (url) {
        return URL.createObjectURL(url)
      }
      return require('@/assets/icon-photo.svg')
    },
  },
}
</script>
