<template>
  <c-box
    v-chakra="{
      ':hover': {
        backgroundColor: content ? '#F2F2F2' : 'primary.50',
      },
    }"
    pos="relative"
    width="30px"
    height="30px"
    p="0"
    border-radius="100%"
    cursor="pointer"
    font-size="14px"
    font-weight="600"
    :background-color="isSelected ? 'primary.50' : (content ? '#F2F2F2' : '#FFF')"
    @click="onClick"
  >
    <c-popover
      v-if="content"
      trigger="hover"
      placement="bottom"
    >
      <c-popover-trigger>
        <c-flex 
          pos="relative"  
          width="100%" 
          height="100%"
          justify="center"
          align="center"
        >
          {{ day }}
          <c-box
            v-if="content"
            pos="absolute"
            bottom="0"
            width="5px"
            height="5px"
            border-radius="100%"
            background-color="danger.500"
          />
        </c-flex>
      </c-popover-trigger>
      <c-popover-content 
        border="0" 
        z-index="4" 
        width="220px" 
        color="white" 
        background-color="info.800"
      >
        <c-popover-body
          font-weight="500"
          text-align="center"
          overflow="hidden"
          white-space="nowrap"
          text-overflow="ellipsis"
        >
          {{ content }}
        </c-popover-body>
        <c-popover-arrow />
      </c-popover-content>
    </c-popover>
    <c-flex
      v-else
      width="100%" 
      height="100%"
      justify="center"
      align="center"
    >
      {{ day }}
    </c-flex>
  </c-box>
</template>

<script>
export default {
  name: 'DayPopover',
  props: {
    day: {
      type: Number,
      default: null,
    },
    month: {
      type: Number,
      default: null,
    },
    content: {
      type: String,
      default: '',
    },
    selected: {
      type: Object,
      default: () => ({
        month: null,
        day: null,
      }),
    },
  },
  emits: ['click'],
  computed: {
    isSelected() {
      if (this.selected.month && this.selected.day && this.selected.month == this.month && this.selected.day == this.day) {
        return true
      }
      return false
    },
  },
  methods: {
    onClick() {
      if (!this.content) {
        const data = {
          day: this.day,
          month: this.month,
        }
        this.$emit('click', data)
      }
    },
  },
}
</script>

<style>

</style>