import { render, staticRenderFns } from "./modal-filter.vue?vue&type=template&id=006e3853&"
import script from "./modal-filter.vue?vue&type=script&lang=js&"
export * from "./modal-filter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CText: require('@chakra-ui/vue').CText, CModalHeader: require('@chakra-ui/vue').CModalHeader, CModalCloseButton: require('@chakra-ui/vue').CModalCloseButton, CImage: require('@chakra-ui/vue').CImage, CFlex: require('@chakra-ui/vue').CFlex, CCheckbox: require('@chakra-ui/vue').CCheckbox, CBox: require('@chakra-ui/vue').CBox, CModalBody: require('@chakra-ui/vue').CModalBody, CModalFooter: require('@chakra-ui/vue').CModalFooter, CModalContent: require('@chakra-ui/vue').CModalContent, CModalOverlay: require('@chakra-ui/vue').CModalOverlay, CModal: require('@chakra-ui/vue').CModal})
