<template>
  <c-modal 
    :is-open="isOpen" 
    :on-close="onClose" 
    :close-on-overlay-click="false" 
    size="xl" 
    is-centered
    scroll-behavior="outside"
    overflow-y="hidden"
    max-height="100vh"
  >
    <c-modal-content 
      ref="content" 
      border-radius="1rem"
      height="fit-content"
      margin="0"
    >
      <c-modal-header>
        <c-text font-size="20px">
          Filter
        </c-text>
      </c-modal-header>
      <c-modal-close-button />
      <c-modal-body
        max-height="60vh"
        overflow-y="auto"
      >
        <c-box
          v-for="category in data"
          :key="category.id"
          w="100%"
        >
          <c-flex
            justify-content="space-between"
            align="center"
            mb="8px"
          >
            <c-flex
              align="center"
              gap="1rem"
            >
              <c-image
                w="40px"
                h="40px"
                :src="category.image"
              />
              <c-text
                font-size="18px"
                font-weight="600"
              >
                {{ category.categoryName }}
              </c-text>
            </c-flex>
            <c-flex
              font-size="18px"
              font-weight="600"
            > 
              <c-checkbox 
                size="lg"
                flex-direction="row-reverse"
                color="primary"
                variant-color="primary"
                :is-checked="isCategoryChecked(category.id)"
                :pointer-events="isCategoryDisabled(category.subCategories) ? 'none' : 'unset'"
                @change="(val, $e) => onCheckCategory(category.id, $e.target.checked, category.subCategories)"
              />
            </c-flex>
          </c-flex>
          <c-flex 
            mb="1rem"
            w="100%"
            flex-wrap="wrap"
            p="8px"
            gap="4"
          >
            <!-- <c-checkbox-group
              v-if="category.subCategories.length > 0"
              v-model="subs_"
              variant-color="primary"
              size="lg"
              :font-size="['14px', '16px']"
              font-weight="400"
              color="#111"
              spacing="4"
              is-inline
            >
              <template v-for="(sub, i) in category?.subCategories">
                <c-checkbox
                  :key="i"
                  :value="sub?.id"
                  font-weight="400"
                  @change="(val, $e) => onChangeSubcatories(category.id, sub.id, $e.target.checked)"
                >
                  {{ sub.subCategoryName }}
                </c-checkbox>
              </template>
            </c-checkbox-group> -->
            <c-checkbox
              v-for="sub in category?.subCategories"
              :key="sub.id"
              variant-color="primary"
              size="lg"
              :font-size="['14px', '16px']"
              font-weight="400"
              color="#111"
              is-inline
              :is-checked="subs_.includes(sub.id)"
              @change="(val, $e) => onChangeSubcatories(category.id, sub.id, $e.target.checked)"
            >
              {{ sub.subCategoryName }}
            </c-checkbox>
          </c-flex>
        </c-box>
      </c-modal-body>
      <c-modal-footer>
        <BaseButton 
          mx="auto" 
          :width="['150px', '300px']" 
          @click.prevent="onSave"
        > 
          Simpan Filter
        </BaseButton>
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import _ from 'lodash'
import BaseButton from '@/components/elements/base-button.vue'

export default {  
  name: 'ModalFilter',
  components: {
    BaseButton,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
      required: true,
    },
    data: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: Object,
      default: () => ({}),
    },
    categories: {
      type: Array,
      default: () => [],
    },
    subCategories: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'save', 'update:categories', 'update:subCategories'],
  data() {
    return {
      selected_: null,
      cats_: this.categories,
      subs_: this.subCategories,
    }
  },
  watch: {
    selected: {
      immediate: true,
      handler(val) {
        if (val && !_.isEmpty(val)) {
          this.selected_ = val
        } else {
          this.selected_ = Object.fromEntries(this.data.map((it) => [it.id, []]))
        }
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close')
    },
    onSave() {
      this.$emit('update:categories', this.cats_)
      this.$emit('update:subCategories', this.subs_)
      // this.$emit('save', this.selected_)
      this.onClose()
    },
    isCategoryChecked(categoryId) {
      if (this.cats_.length > 0) {
        const check = this.cats_.some((it) => it == categoryId)
        if (check) {
          return true
        }
      }
      return false
    },
    isCategoryDisabled(subs) { 
      const sameValue = subs.filter((it) => this.subs_.includes(it.id))
      if (sameValue.length > 0) {
        return true
      }
      return false
    },
    onCheckCategory(categoryId, value, subs) {
      const sameValue = subs.filter((it) => this.subs_.includes(it.id))
      if (subs.length > 0 && sameValue.length > 0) {
        if (!this.isCategoryChecked(categoryId)) {
          this.cats_ = [...this.cats_, categoryId]
        }
      } else {
        if (value) {
          this.cats_ = [...this.cats_, categoryId]
        } else {
          this.cats_ = this.cats_.filter((it) => it != categoryId)
        }
      }
    },
    onChangeSubcatories(categoryId, subId, value) {
      if (value) {
        this.subs_ = [...this.subs_, subId]
      } else {
        this.subs_ = this.subs_.filter((it) => it != subId)
      }

      const isCheckedCategory = this.isCategoryChecked(categoryId)
      if (!isCheckedCategory) {
        this.cats_ = [...this.cats_, categoryId]
      }
    },
    onCheckAll(categoryId, value) {
      if (value) {
        const categories = this.data.find((it) => it.id == categoryId)
        if (this.selected_[categoryId] && categories && categories?.subCategories?.length > 0) {
          const subCategories = categories.subCategories.map((it) => {
            return it.id
          })
          this.selected_[categoryId] = subCategories
        }
      } else {
        this.selected_[categoryId] = []
      }
    },
    isCheckedAll(categoryId) {
      const categories = this.data.find((it) => it.id == categoryId)
      if (this.selected_ && this.selected_[categoryId] && categories?.subCategories?.length > 0) {
        return this.selected_[categoryId].length == categories?.subCategories?.length ? true : false
      }
      return false
    },
  },
}
</script>

<style></style>
