import { render, staticRenderFns } from "./form.vue?vue&type=template&id=6c4d80f8&scoped=true&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"
import style0 from "./form.vue?vue&type=style&index=0&id=6c4d80f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c4d80f8",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CHeading: require('@chakra-ui/vue').CHeading, CImage: require('@chakra-ui/vue').CImage, CButton: require('@chakra-ui/vue').CButton, CFlex: require('@chakra-ui/vue').CFlex, CInput: require('@chakra-ui/vue').CInput, CBox: require('@chakra-ui/vue').CBox, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormControl: require('@chakra-ui/vue').CFormControl, CRadio: require('@chakra-ui/vue').CRadio, CRadioGroup: require('@chakra-ui/vue').CRadioGroup, CDivider: require('@chakra-ui/vue').CDivider, CAlertIcon: require('@chakra-ui/vue').CAlertIcon, CAlert: require('@chakra-ui/vue').CAlert})
