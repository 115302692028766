<template>
  <c-flex
    align="baseline"
    gap="32px"
  >
    <BaseLabel
      :value="image"
      label="Gambar"
      is-required
    >
      <label
        v-chakra="{
          bg: 'white',
          cursor: 'pointer',
          border: '1px solid #C4C4C4',
          boxSizing: 'border-box',
          borderRadius: '6px',
          w: '180px',
          h: '100%',
          minHeight: '180px',
          d: 'flex',
          p: '0',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
        }"
        :for="`img-program-${idx}`"
      >
        <c-image
          v-if="image"
          :src="getPreviewImage(image)"
          :h="
            !getPreviewImage(image).includes('icon-photo')
              ? '100%'
              : '94px'
          "
          alt="img program"
          mx="auto"
          border-radius="6px"
        />
        <inline-svg
          v-else
          :src="require('@/assets/icons/icon-image-preview.svg')"
          height="100px"
          width="100px"
          fill="#008C81"
        />
      </label>
      <c-input
        :id="`img-program-${idx}`"
        style="display: none"
        type="file"
        accept=".jpg, .jpeg, .png"
        error-border-color="red.300"
        placeholder="Masukkan Deskripsi Konten"
        height="62px"
        @change="onFileChange($event)"
      />
    </BaseLabel>
    
    <BaseLabel
      :value="description"
      label="Caption Gambar"
      full-width
    >
      <c-textarea
        v-chakra="{
          border: '1px solid #C4C4C4',
          padding: '16px',
          width: '100%',
          borderRadius: '6px',
          height: '180px',
          '&:focus, &:focus-visible': {
            outline: 'unset',
          },
        }"
        font-size="18px"
        color="primary.400"
        font-weight="500"
        focus-border-color="primary.400"
        :value="description"
        min-height="180px"
        placeholder="Masukkan Caption Gambar"
        @change="(value) => $emit('change-description', value)"
      />
    </BaseLabel>
  </c-flex>
</template>

<script>
import BaseLabel from '@/components/elements/base-label.vue'

export default {
  name: 'SectionImage',
  components: {
    BaseLabel,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    image: {
      type: [String, Object, File],
      default: '',
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change-description', 'change-image'],
  computed: {
  },
  methods: {
    onFileChange(input) {
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        // if (file.type.includes('image') && file.size > 2097152) {
        //   return this.$toast({
        //     title: 'Failed',
        //     description: `This file can't be uploaded, because it (${this.formatBytes(
        //       file.size,
        //     )}) exceeds the maximum file size (2 MB).`,
        //     status: 'error',
        //     duration: 10000,
        //     position: 'bottom-right',
        //     variant: 'subtle',
        //   })
        // }
        this.$emit('change-image', file)
      }
    },
    getPreviewImage(url) {
      if (url && typeof url == 'string') {
        return url
      }
      if (url) {
        return URL.createObjectURL(url)
      }
      return require('@/assets/icon-photo.svg')
    },
  },
}
</script>
