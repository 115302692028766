var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "align": "baseline"
    }
  }, [_c('c-form-control', {
    attrs: {
      "min-w": "180px",
      "w": "180px",
      "mr": "40px",
      "is-invalid": true
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "#555555",
      "font-weigh": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Upload File ")]), _c('label', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        bg: 'white',
        cursor: 'pointer',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '6px',
        w: '180px',
        h: '180px',
        d: 'flex',
        p: '0',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      expression: "{\n        bg: 'white',\n        cursor: 'pointer',\n        border: '1px solid #C4C4C4',\n        boxSizing: 'border-box',\n        borderRadius: '6px',\n        w: '180px',\n        h: '180px',\n        d: 'flex',\n        p: '0',\n        alignItems: 'center',\n        justifyContent: 'center',\n        overflow: 'hidden',\n      }"
    }],
    attrs: {
      "for": "file-program-".concat(_vm.idx)
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-folder.svg'),
      "height": "140",
      "width": "140",
      "fill": "#008C81"
    }
  })], 1), _c('c-input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "file-program-".concat(_vm.idx),
      "type": "file",
      "accept": ".pdf,.doc,.docx,application/msword",
      "error-border-color": "red.300",
      "placeholder": "Masukkan Deskripsi Konten",
      "height": "62px"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileChange($event);
      }
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "align-self": "center",
      "w": "100%",
      "is-invalid": true
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": "14px",
      "color": "gray.900",
      "font-weigh": "normal",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.getPreviewFile) + " ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }